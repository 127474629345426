<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="searchParams.coin_kind_item_version_goods_name" size='small' style="width:150px;margin:5px;" placeholder='钱币商品名称'></a-input>
                <a-input v-model="searchParams.coin_kind_name" size='small' style="width:150px;margin:5px;" placeholder='大币种'></a-input>
                <a-input v-model="searchParams.coin_kind_item_name" size='small' style="width:150px;margin:5px;" placeholder='小币种'></a-input>
                <a-input v-model="searchParams.coin_kind_item_version_name" size='small' style="width:150px;margin:5px;" placeholder='版别'></a-input>
                <a-input v-model="searchParams.coin_kind_item_version_goods_grade" size='small' style="width:150px;margin:5px;" placeholder='评分'></a-input>
                <a-input v-model="searchParams.coin_kind_item_version_goods_company" size='small' style="width:150px;margin:5px;" placeholder='评级公司'></a-input>
                <a-range-picker v-model="searchParams.times" :placeholder="['开始日期','结束日期']" size='small' style="width:200px;margin:5px;"/>
                <a-select  allowClear v-model="searchParams.priceSort" size='small' style="width:100px;margin:5px;" placeholder='价格'>
                   <a-select-option :value='10'>正序</a-select-option>
                    <a-select-option :value='11'>倒序</a-select-option>
                </a-select>
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
            <div>
                <a-button size='small' @click="openModal({})">新增钱币商品</a-button>
            </div>
        </div>
        <div class="table-content" style="padding-top:20px;">
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="record=>record.id">
            
                <div slot='coinKindItemVersionGoodsName' slot-scope="row">
                    <a @click="look_detail(row)">{{row.coinKindItemVersionGoodsName}}</a>
                </div>

                <div slot="Image" slot-scope="row" style="width:50px;height:50px;display:flex;justify-content:center;align-items:center;">
                    <img style="max-width:50px;max-height:50px;" :src="row">
                </div>

                <div slot="tags" slot-scope="row">
                    <a-tag v-for="(tag,key) in row.tagList" :key="key" color="blue">{{ tag.coinTagName }}</a-tag>
                </div>
                 <div slot="setup" slot-scope="row">
                    <a @click="openModal(row)" style='margin-right:5px'>编辑</a>
                    <a-popconfirm
                        title="Are you sure delete this item?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="remove(row.id)"
                    >
                    <a style='margin-left:5px'>删除</a>
                    </a-popconfirm>
                </div>
            </a-table>
        </div>

        <a-modal :width='700' v-model="visible" :title="innerTitle" ok-text="确认" cancel-text="取消" @cancel='resetForms' @ok="hideModal">
            <div style="width:100%;height:500px;overflow-y:scroll;">
                <a-form-model
                    ref="ruleForm"
                    :model="forms"
                    :rules="rules"
                    v-bind="layout">   
                    <a-form-model-item label="钱币名称" prop="coinKindItemVersionGoodsName" >
                        <a-input size='small' placeholder='钱币名称' v-model="forms.coinKindItemVersionGoodsName"/>
                    </a-form-model-item>
                    <a-form-model-item label="大币种" prop="coinKindSid" >
                        <a-select size='small' @search='searchData' ref='addse' v-model="forms.coinKindSid" :filter-option="untils.filterOption" showSearch  @change='changeBigCoinSelect($event)' placeholder='请选择' >
                            <a-select-option v-for="item of currency_big_list" :key="item.id" :value='item.sid'>{{item.coinKindName}}</a-select-option>
                        </a-select>
                    </a-form-model-item> 
                    <a-form-model-item label="小币种">
                        <a-select size='small' mode='tags' :filter-option="untils.filterOption" showSearch v-model="forms.coinKindItemSid" @change='changeSelect($event,2)'  placeholder='请选择'>
                            <a-select-option  v-for="item of currency_small_list" :key="item.id" :value='item.sid'>{{item.coinKindItemName}}</a-select-option>
                        </a-select>
                    </a-form-model-item> 
                    <a-form-model-item label="版别名称">
                        <a-select size='small' mode='tags' placeholder='请选择' :filter-option="untils.filterOption" showSearch v-model="forms.coinKindItemVersionSid" @change='changeSelect($event,3)'>
                            <a-select-option  v-for="item of bottles" :key="item.sid" :value='item.sid'>{{item.coinKindVersionName}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label='截拍时间'>
                        <a-date-picker size='small' :defaultValue="moment(forms.createTime, 'YYYY-MM-DD')"  format='YYYY-MM-DD HH:mm:ss' placeholder='截拍时间'  @change="changeTime" />
                    </a-form-model-item>
                    
                    <a-form-model-item label='评级公司'>
                        <div style="display:flex;align-items:center;">
                            <a-select size='small' mode='tags' style="width:150px;" placeholder='请选择'  :filter-option="untils.filterOption" showSearch v-model="forms.coinKindItemVersionGoodsCompany">
                                <a-select-option v-for="item of company" :key="item" :value='item'>{{item}}</a-select-option>
                            </a-select>
                            <span style='margin:0 5px'>评分</span>
                            <a-input size='small' style="width:50px;" placeholder='评分' v-model="forms.coinKindItemVersionGoodsGrade"/>
                            <span style='margin:0 5px'>价格</span>
                            <a-input size='small' style="width:100px;" placeholder='价格' v-model="forms.coinKindItemVersionGoodsPrice"/>
                            <span style='margin:0 5px'>级别</span>
                            <a-input size='small' style="width:100px;" placeholder='级别' v-model="forms.coinKindItemVersionGoodsLevel"/>
                        </div>
                    </a-form-model-item>
                     <a-form-item label='版别正面'>
                        <div style="display:flex;align-items:center;height:100%;">
                            <a-input allowClear @paste.stop.prevent="untils.pasteImg($event,1,parsetCallback)" v-model="forms.front_img" size='small' style="width:200px;">
                                <a-upload
                                    slot='addonBefore'
                                    name="file"
                                    :multiple="true"
                                    :showUploadList='false'
                                    :action="ip+'/file/upload'"
                                    @change="handleChange($event,forms,1)">
                                    <a> <a-icon type="upload"/></a>
                                </a-upload>
                            </a-input>
                            <span style="margin:0 5px;">版别背面:</span>
                            <a-input allowClear @paste.stop.prevent="untils.pasteImg($event,2,parsetCallback)" v-model='forms.contrary_img' size='small' style="width:200px">
                                <a-upload
                                    slot='addonBefore'
                                    name="file"
                                    :showUploadList='false'
                                    :action="ip+'/file/upload'"
                                    @change="handleChange($event,forms,2)">
                                    <a> <a-icon type="upload"/></a>
                                </a-upload>
                            </a-input>
                        </div>
                    </a-form-item>
                    <a-form-item label='重量'>
                        <div style="display:flex;align-items:center">
                            <a-input style="width:150px" suffix="克" size='small' v-model="forms.weight"></a-input>
                            <span style="margin:0 5px;">尺寸:</span>
                            <a-input v-model="forms.measure" size='small' style="width:150px;"></a-input>
                        </div>
                        
                    </a-form-item>
                    <a-form-item label='描述'><a-input size='small' v-model="forms.goodsDesc"></a-input></a-form-item>
                    <!-- <a-form-model-item label="重量">
                        <a-input placeholder='重量' v-model="forms.weight"/>
                    </a-form-model-item> -->

                    <a-form-model-item label='标签'>
                        <a-tag closable @close='removeTag(_index)' v-for="(_item,_index) in forms.tagList" :key="_index" @click="confirmTag(_item,_index)">{{_item.coinTagName}}</a-tag>
                        <a-button @click="confirmTag({})" size='small'>添加</a-button>
                    </a-form-model-item>

                    <a-form-item label='附加属性'>
                        <div v-for="(item,index) of forms.coinKindItemVersionGoodsJson" :key="index" style="display:flex;align-items:center;justify-content:space-between;">
                            <div style="display:flex;flex-wrap:no-wrap;align-items:center;">属性名:<a-input size='small' v-model="item.title" style="width:150px;"></a-input>属性值:<a-input size='small' v-model="item.value" style="width:150px;"></a-input></div>
                            <a-button @click="addAttrs(forms.coinKindItemVersionGoodsJson)" size='small'>添加</a-button>
                            <a-button @click="remove(forms.coinKindItemVersionGoodsJson,index)" type='danger' size='small'>删除</a-button>
                        </div>
                    </a-form-item>

                </a-form-model>
            </div>
        </a-modal>

        <a-modal v-model="tagVisible" title="标签" ok-text="确认" cancel-text="取消" @cancel='tagForms = {}' @ok="addTag">
            <a-form-model
                ref="tagforms"
                :model="tagForms"
                :rules="tagRules"
                v-bind="layout">   
                <a-form-model-item label="名称" prop="coinTagName" >
                    <a-input placeholder='名称' v-model="tagForms.coinTagName"/>
                </a-form-model-item>

                <a-form-model-item label='标签分类' prop='coinTagCategory'>
                    <a-select placeholder='标签分类' v-model="tagForms.coinTagCategory">
                        <a-select-option :value='1'>别名标签</a-select-option>
                        <a-select-option :value='2'>钱币性质标签</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment';
import 'moment/locale/zh-cn';
export default {
    data(){
        return {
            innerText:'123321',
            columns:[
                {title:'ID',dataIndex:'id'},
                {title:'钱币名称',scopedSlots: { customRender: 'coinKindItemVersionGoodsName' },},
                {title:'正图',dataIndex:'front_img',scopedSlots: { customRender: 'Image' },},
                {title:'反图',dataIndex:'contrary_img',scopedSlots: { customRender: 'Image' },},
                {title:'大币种',dataIndex:'coinKindName'},
                {title:'小币种',dataIndex:'coinKindItemName'},
                {title:'版别',dataIndex:'coinKindItemVersionName'},
                {title:'评级公司',dataIndex:'coinKindItemVersionGoodsCompany'},
                {title:'评分',dataIndex:'coinKindItemVersionGoodsGrade'},
                {title:'价格',dataIndex:'coinKindItemVersionGoodsPrice'},
                {title:'级别',dataIndex:'coinKindItemVersionGoodsLevel'},
                {title:'标签',scopedSlots:{customRender:'tags'}},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            times:[],
            type_list:[
                {title:'微拍堂',type:1},
                {title:'古泉园地',type:2},
                {title:'聚藏天下',type:3},
                {title:'微钱币',type:4},
                {title:'钱币天堂',type:5},
                {title:'玩物得志',type:6},
                {title:'爱藏',type:7},
                {title:'收藏圈',type:8},
                {title:'7788收藏',type:9},
                {title:'赵涌在线',type:10},
            ],
            company:["公博","保粹","闻德 ","华夏","众诚详评","园地","PCGS"],
            moment,
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            forms:{},
            tagForms:{},
            rules:{
                coinKindSid:[{ required: true, message: 'please change', trigger: 'change' }],
                coinKindVersionName:[{ required: true, message: 'please enter coinKindVersionName', trigger: 'blur' }],
                coinKindItemSid:[{ required: true, message: 'please enter coinKindItemName', trigger: 'blur' }],
            },
            tagRules:{
                coinTagCategory:[{ required: true, message: 'please change', trigger: 'change' }],
                coinTagName:[{ required: true, message: 'please change', trigger: 'change' }], 
            },
            currency_small_list:[],
            currency_big_list:[],
            bottles:[],
            visible:false,
            tagVisible:false,
            innerTitle:'',
            currency_type_list:[],
            params:{page:1,limit:10,conditions:''},
            IO:0,
            tagCurrent:'',
            pagination:{
                total:0,
                current:1,
                'show-quick-jumper':true,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            searchParams:{
                coin_kind_item_version_goods_name:'',
                coin_kind_name:'',
                coin_kind_item_name:'',
                coin_kind_item_version_name:'',
                coin_kind_item_version_goods_grade:'',
                coin_kind_item_version_goods_company:'',
                createStartTime:'',
                createEndTime:'',
                priceSort:'',
            },
            
        }
    },
    computed:{
        ...mapState(['ip'])
    },
    created(){
        this.getData(this.params)
    },
    mounted(){
    },
    methods:{
        openModal(row){
            const {id} = row
            const type = id ? 1 : 0
            this.IO = type
            this.visible = true
            if(type == 0){
                this.innerTitle ='新增钱币商品'
                this.forms = {
                    tagList:[],
                    coinKindItemVersionGoodsJson:[{title:'',value:''}],
                    coinKindSid:[],
                    coinKindItemSid:[],
                    coinKindItemVersionSid:[],
                    coinKindItemVersionGoodsDealTime:'',
                    front_img:'',contrary_img:''
                }
            }else{
                this.innerTitle ='编辑钱币商品'
                this.forms = JSON.parse(JSON.stringify(row))
                if(!this.forms.coinKindItemVersionGoodsCompany){
                    this.forms.coinKindItemVersionGoodsCompany = []
                }
                if(this.forms.coinKindSid){
                    this.currency_big_list = [{id:1,sid:this.forms.coinKindSid,coinKindName:this.forms.coinKindName}]
                    this.axios('/dq_admin/kind/getItemAndVersion',{params:{sid:this.forms.coinKindSid}}).then(res=>{
                        const {coinItem,coinItemVersion} = res.data
                        this.bottles = coinItemVersion
                        this.currency_small_list = coinItem
                        if(this.forms.coinKindItemSid){
                            this.axios('/dq_admin/kinditem/getInnerVersionList',{params:{sid:this.forms.coinKindItemSid}}).then(result=>{
                                const {data} = result
                                if(data&&data.length>0){
                                    this.bottles = data
                                }
                            })
                        }else{
                            this.forms.coinKindItemSid = []
                        }
                    })
                }
            }
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        resetForms(){
            this.forms = {}
            this.$refs.ruleForm.resetFields();
        },
        submit(forms){
            const newForms = JSON.parse(JSON.stringify(forms))
            try{
                if(typeof newForms.coinKindItemVersionSid == 'string'){
                    newForms.coinKindItemVersionSid = [newForms.coinKindItemVersionSid]||[]
                }
                if(typeof newForms.coinKindItemSid&&newForms.coinKindItemSid == 'string'){
                    newForms.coinKindItemSid = [newForms.coinKindItemSid]
                }
                if( typeof newForms.coinKindSid&&newForms.coinKindSid == 'string'){
                    newForms.coinKindSid = [newForms.coinKindSid]
                }
                if(Array.isArray(newForms.coinKindItemVersionGoodsCompany)){
                    newForms.coinKindItemVersionGoodsCompany = newForms.coinKindItemVersionGoodsCompany[0] || ''
                }
                newForms.coinKindItemVersionGoodsJson = JSON.stringify(newForms.coinKindItemVersionGoodsJson)
            }catch{

            }
            const post_url = this.IO == 1 ? '/dq_admin/kind_item_version_goods/edit' : '/dq_admin/kind_item_version_goods/add'
            this.axios.post(post_url,newForms).then(res=>{
                if(res.status == 'SUCCESS'){
                    this.$message.success(res.message)
                    this.visible = false
                    this.getData(this.params)
                }
            })
        },
        async getData(params){
            this.loading = true
            const res = await this.axios('/dq_admin/kind_item_version_goods/list',{params:params})
            this.loading = false
            res.data.records.map((row)=>{ row.coinKindItemVersionGoodsJson = JSON.parse(row.coinKindItemVersionGoodsJson)})
            this.data = res.data.records
            this.pagination.total = res.data.total
        },
        search(){
            if(this.searchParams.times===undefined||this.searchParams.times===""){
                this.searchParams.createStartTime = ''
                this.searchParams.createEndTime = ''
            }else{
                const result = this.searchParams.times.map(row=>{
                    const time = new Date(row._d).toLocaleDateString().replace(/\//g,"-")
                    return time
                })
                if(result&&result.length>0){
                    this.searchParams.createStartTime = result[0]
                    this.searchParams.createEndTime = result[1]
                }
            }
            const strs = this.untils.setParams(this.searchParams)
            this.params.conditions = strs
            this.params.page = 1
            this.searchParams.createStartTime = ''
            this.searchParams.createEndTime = ''
            this.getData(this.params)
        },
        async remove(params){
            const res = await this.axios('/dq_admin/kind_item_version_goods/delKindItemVersionGoodsById',{params:{id:params}})
            if(res.status == 'SUCCESS'){
                this.$message.success(res.message)
                this.getData(this.params)
            }
        },
        addTag(){
            this.$refs.tagforms.validate(valid => {
                if(valid){
                    if(typeof this.tagCurrent == 'number'){
                        this.forms.tagList.splice(this.tagCurrent,1,this.tagForms)
                    }else{
                        this.forms.tagList.push(this.tagForms)
                    }
                    this.tagVisible = false
                }else{
                    return false;
                }
            });
        },
        confirmTag(row,index){
            this.tagVisible = true
            this.tagForms = row
            this.tagCurrent = index || index === 0 ? index : ''
        },
        removeTag(n){
            this.forms.tagList.splice(n,1)
        },
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.page = current
            this.params.limit = pageSize
            this.getData(this.params)
        },
        searchData(inner){
            this.axios('/dq_admin/kind/list',{params:{
                coinName: inner
            }}).then(res=>{
                if(res.status == '200'){
                    const {records} = res.data
                    this.currency_big_list = records
                }
            })
        },
        changeBigCoinSelect(sid){
            if(sid&&sid.length>1){
                // const result = this.replaceSid(this.currency_big_list,'coinKindName',sid.join(','))
                // if(result&&result.length>0){
                //     sid = [result[0].sid]
                // }
                this.forms.coinKindSid = sid
                this.axios('/dq_admin/kind/getItemAndVersion',{params:{sid:sid}}).then(res=>{
                    const {coinItem,coinItemVersion} = res.data
                    this.bottles = coinItemVersion
                    this.currency_small_list = coinItem
                })
            }
        },

        changeSelect(sid,type){
            if(sid&&sid.length>1){
                sid.length = 1
            }
            if(sid&&sid.length==1){
                if(type == 1){
                    const result = this.replaceSid(this.currency_big_list,'coinKindName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    this.forms.coinKindSid = sid
                    this.axios('/dq_admin/kind/getItemAndVersion',{params:{sid:sid.join(',')}}).then(res=>{
                        const {coinItem,coinItemVersion} = res.data
                        this.bottles = coinItemVersion
                        this.currency_small_list = coinItem
                    })
                }else if(type == 2){
                    const result = this.replaceSid(this.currency_small_list,'coinKindItemName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    this.forms.coinKindItemSid = sid
                    this.axios('/dq_admin/kinditem/getInnerVersionList',{params:{sid:sid.join(',')}}).then(res=>{
                        const {data} = res
                        this.bottles = data
                    })
                }else{
                    const result = this.replaceSid(this.bottles,'coinKindVersionName',sid.join(','))
                    if(result&&result.length>0){
                        sid = [result[0].sid]
                    }
                    this.forms.coinKindItemVersionSid = sid
                }
            }
        },
        replaceSid(array,key,name){
            const result = array.filter(item=>item[key] == name)
            return result
        },
        changeTime(data,date){
            this.forms.coinKindItemVersionGoodsDealTime = date
        },
        look_detail(row){
            const {coinKindItemVersionGoodsName,coinKindItemVersionGoodsPrice} = row
            const routeData = this.$router.resolve({
            path: "/cms/currencymannage/dqmannage/datamannage",
            query: {
                title:coinKindItemVersionGoodsName,
                price:coinKindItemVersionGoodsPrice,
            }
            });
            window.open(routeData.href, '_blank');
        },
        addAttrs(array){
            array.push({title:'',value:''})
        },
        handleChange(res,form,type){
            try{
                const {response} = res.file
                if(response.data){
                    const {data} = response
                    if(data){
                        type == 1 ? form.front_img = data : form.contrary_img = data
                    }
                }
            }catch(e){}  
        },
        parsetCallback(type,data){
            type == 1 ? this.forms.front_img = data : this.forms.contrary_img = data
        }
        
        
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>