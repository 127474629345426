var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "钱币商品名称" },
              model: {
                value: _vm.searchParams.coin_kind_item_version_goods_name,
                callback: function($$v) {
                  _vm.$set(
                    _vm.searchParams,
                    "coin_kind_item_version_goods_name",
                    $$v
                  )
                },
                expression: "searchParams.coin_kind_item_version_goods_name"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "大币种" },
              model: {
                value: _vm.searchParams.coin_kind_name,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "coin_kind_name", $$v)
                },
                expression: "searchParams.coin_kind_name"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "小币种" },
              model: {
                value: _vm.searchParams.coin_kind_item_name,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "coin_kind_item_name", $$v)
                },
                expression: "searchParams.coin_kind_item_name"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "版别" },
              model: {
                value: _vm.searchParams.coin_kind_item_version_name,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "coin_kind_item_version_name", $$v)
                },
                expression: "searchParams.coin_kind_item_version_name"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "评分" },
              model: {
                value: _vm.searchParams.coin_kind_item_version_goods_grade,
                callback: function($$v) {
                  _vm.$set(
                    _vm.searchParams,
                    "coin_kind_item_version_goods_grade",
                    $$v
                  )
                },
                expression: "searchParams.coin_kind_item_version_goods_grade"
              }
            }),
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "评级公司" },
              model: {
                value: _vm.searchParams.coin_kind_item_version_goods_company,
                callback: function($$v) {
                  _vm.$set(
                    _vm.searchParams,
                    "coin_kind_item_version_goods_company",
                    $$v
                  )
                },
                expression: "searchParams.coin_kind_item_version_goods_company"
              }
            }),
            _c("a-range-picker", {
              staticStyle: { width: "200px", margin: "5px" },
              attrs: { placeholder: ["开始日期", "结束日期"], size: "small" },
              model: {
                value: _vm.searchParams.times,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "times", $$v)
                },
                expression: "searchParams.times"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "价格" },
                model: {
                  value: _vm.searchParams.priceSort,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "priceSort", $$v)
                  },
                  expression: "searchParams.priceSort"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 10 } }, [
                  _vm._v("正序")
                ]),
                _c("a-select-option", { attrs: { value: 11 } }, [
                  _vm._v("倒序")
                ])
              ],
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal({})
                  }
                }
              },
              [_vm._v("新增钱币商品")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record) {
                return record.id
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "coinKindItemVersionGoodsName",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.look_detail(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.coinKindItemVersionGoodsName))]
                    )
                  ])
                }
              },
              {
                key: "Image",
                fn: function(row) {
                  return _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center"
                      }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "max-width": "50px",
                          "max-height": "50px"
                        },
                        attrs: { src: row }
                      })
                    ]
                  )
                }
              },
              {
                key: "tags",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    _vm._l(row.tagList, function(tag, key) {
                      return _c(
                        "a-tag",
                        { key: key, attrs: { color: "blue" } },
                        [_vm._v(_vm._s(tag.coinTagName))]
                      )
                    }),
                    1
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 700,
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "500px",
                "overflow-y": "scroll"
              }
            },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "钱币名称",
                        prop: "coinKindItemVersionGoodsName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { size: "small", placeholder: "钱币名称" },
                        model: {
                          value: _vm.forms.coinKindItemVersionGoodsName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.forms,
                              "coinKindItemVersionGoodsName",
                              $$v
                            )
                          },
                          expression: "forms.coinKindItemVersionGoodsName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "大币种", prop: "coinKindSid" } },
                    [
                      _c(
                        "a-select",
                        {
                          ref: "addse",
                          attrs: {
                            size: "small",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          on: {
                            search: _vm.searchData,
                            change: function($event) {
                              return _vm.changeBigCoinSelect($event)
                            }
                          },
                          model: {
                            value: _vm.forms.coinKindSid,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "coinKindSid", $$v)
                            },
                            expression: "forms.coinKindSid"
                          }
                        },
                        _vm._l(_vm.currency_big_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.sid } },
                            [_vm._v(_vm._s(item.coinKindName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "小币种" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            size: "small",
                            mode: "tags",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: "",
                            placeholder: "请选择"
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSelect($event, 2)
                            }
                          },
                          model: {
                            value: _vm.forms.coinKindItemSid,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "coinKindItemSid", $$v)
                            },
                            expression: "forms.coinKindItemSid"
                          }
                        },
                        _vm._l(_vm.currency_small_list, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.sid } },
                            [_vm._v(_vm._s(item.coinKindItemName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "版别名称" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            size: "small",
                            mode: "tags",
                            placeholder: "请选择",
                            "filter-option": _vm.untils.filterOption,
                            showSearch: ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.changeSelect($event, 3)
                            }
                          },
                          model: {
                            value: _vm.forms.coinKindItemVersionSid,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "coinKindItemVersionSid", $$v)
                            },
                            expression: "forms.coinKindItemVersionSid"
                          }
                        },
                        _vm._l(_vm.bottles, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.sid, attrs: { value: item.sid } },
                            [_vm._v(_vm._s(item.coinKindVersionName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "截拍时间" } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          size: "small",
                          defaultValue: _vm.moment(
                            _vm.forms.createTime,
                            "YYYY-MM-DD"
                          ),
                          format: "YYYY-MM-DD HH:mm:ss",
                          placeholder: "截拍时间"
                        },
                        on: { change: _vm.changeTime }
                      })
                    ],
                    1
                  ),
                  _c("a-form-model-item", { attrs: { label: "评级公司" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "150px" },
                            attrs: {
                              size: "small",
                              mode: "tags",
                              placeholder: "请选择",
                              "filter-option": _vm.untils.filterOption,
                              showSearch: ""
                            },
                            model: {
                              value: _vm.forms.coinKindItemVersionGoodsCompany,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.forms,
                                  "coinKindItemVersionGoodsCompany",
                                  $$v
                                )
                              },
                              expression:
                                "forms.coinKindItemVersionGoodsCompany"
                            }
                          },
                          _vm._l(_vm.company, function(item) {
                            return _c(
                              "a-select-option",
                              { key: item, attrs: { value: item } },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                          1
                        ),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("评分")
                        ]),
                        _c("a-input", {
                          staticStyle: { width: "50px" },
                          attrs: { size: "small", placeholder: "评分" },
                          model: {
                            value: _vm.forms.coinKindItemVersionGoodsGrade,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.forms,
                                "coinKindItemVersionGoodsGrade",
                                $$v
                              )
                            },
                            expression: "forms.coinKindItemVersionGoodsGrade"
                          }
                        }),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("价格")
                        ]),
                        _c("a-input", {
                          staticStyle: { width: "100px" },
                          attrs: { size: "small", placeholder: "价格" },
                          model: {
                            value: _vm.forms.coinKindItemVersionGoodsPrice,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.forms,
                                "coinKindItemVersionGoodsPrice",
                                $$v
                              )
                            },
                            expression: "forms.coinKindItemVersionGoodsPrice"
                          }
                        }),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("级别")
                        ]),
                        _c("a-input", {
                          staticStyle: { width: "100px" },
                          attrs: { size: "small", placeholder: "级别" },
                          model: {
                            value: _vm.forms.coinKindItemVersionGoodsLevel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.forms,
                                "coinKindItemVersionGoodsLevel",
                                $$v
                              )
                            },
                            expression: "forms.coinKindItemVersionGoodsLevel"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("a-form-item", { attrs: { label: "版别正面" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          height: "100%"
                        }
                      },
                      [
                        _c(
                          "a-input",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { allowClear: "", size: "small" },
                            on: {
                              paste: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.untils.pasteImg(
                                  $event,
                                  1,
                                  _vm.parsetCallback
                                )
                              }
                            },
                            model: {
                              value: _vm.forms.front_img,
                              callback: function($$v) {
                                _vm.$set(_vm.forms, "front_img", $$v)
                              },
                              expression: "forms.front_img"
                            }
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                attrs: {
                                  slot: "addonBefore",
                                  name: "file",
                                  multiple: true,
                                  showUploadList: false,
                                  action: _vm.ip + "/file/upload"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleChange(
                                      $event,
                                      _vm.forms,
                                      1
                                    )
                                  }
                                },
                                slot: "addonBefore"
                              },
                              [
                                _c(
                                  "a",
                                  [_c("a-icon", { attrs: { type: "upload" } })],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("版别背面:")
                        ]),
                        _c(
                          "a-input",
                          {
                            staticStyle: { width: "200px" },
                            attrs: { allowClear: "", size: "small" },
                            on: {
                              paste: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.untils.pasteImg(
                                  $event,
                                  2,
                                  _vm.parsetCallback
                                )
                              }
                            },
                            model: {
                              value: _vm.forms.contrary_img,
                              callback: function($$v) {
                                _vm.$set(_vm.forms, "contrary_img", $$v)
                              },
                              expression: "forms.contrary_img"
                            }
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                attrs: {
                                  slot: "addonBefore",
                                  name: "file",
                                  showUploadList: false,
                                  action: _vm.ip + "/file/upload"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleChange(
                                      $event,
                                      _vm.forms,
                                      2
                                    )
                                  }
                                },
                                slot: "addonBefore"
                              },
                              [
                                _c(
                                  "a",
                                  [_c("a-icon", { attrs: { type: "upload" } })],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("a-form-item", { attrs: { label: "重量" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("a-input", {
                          staticStyle: { width: "150px" },
                          attrs: { suffix: "克", size: "small" },
                          model: {
                            value: _vm.forms.weight,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "weight", $$v)
                            },
                            expression: "forms.weight"
                          }
                        }),
                        _c("span", { staticStyle: { margin: "0 5px" } }, [
                          _vm._v("尺寸:")
                        ]),
                        _c("a-input", {
                          staticStyle: { width: "150px" },
                          attrs: { size: "small" },
                          model: {
                            value: _vm.forms.measure,
                            callback: function($$v) {
                              _vm.$set(_vm.forms, "measure", $$v)
                            },
                            expression: "forms.measure"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "a-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("a-input", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.forms.goodsDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "goodsDesc", $$v)
                          },
                          expression: "forms.goodsDesc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "标签" } },
                    [
                      _vm._l(_vm.forms.tagList, function(_item, _index) {
                        return _c(
                          "a-tag",
                          {
                            key: _index,
                            attrs: { closable: "" },
                            on: {
                              close: function($event) {
                                return _vm.removeTag(_index)
                              },
                              click: function($event) {
                                return _vm.confirmTag(_item, _index)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_item.coinTagName))]
                        )
                      }),
                      _c(
                        "a-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.confirmTag({})
                            }
                          }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "附加属性" } },
                    _vm._l(_vm.forms.coinKindItemVersionGoodsJson, function(
                      item,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-wrap": "no-wrap",
                                "align-items": "center"
                              }
                            },
                            [
                              _vm._v("属性名:"),
                              _c("a-input", {
                                staticStyle: { width: "150px" },
                                attrs: { size: "small" },
                                model: {
                                  value: item.title,
                                  callback: function($$v) {
                                    _vm.$set(item, "title", $$v)
                                  },
                                  expression: "item.title"
                                }
                              }),
                              _vm._v("属性值:"),
                              _c("a-input", {
                                staticStyle: { width: "150px" },
                                attrs: { size: "small" },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.addAttrs(
                                    _vm.forms.coinKindItemVersionGoodsJson
                                  )
                                }
                              }
                            },
                            [_vm._v("添加")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.remove(
                                    _vm.forms.coinKindItemVersionGoodsJson,
                                    index
                                  )
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "标签", "ok-text": "确认", "cancel-text": "取消" },
          on: {
            cancel: function($event) {
              _vm.tagForms = {}
            },
            ok: _vm.addTag
          },
          model: {
            value: _vm.tagVisible,
            callback: function($$v) {
              _vm.tagVisible = $$v
            },
            expression: "tagVisible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "tagforms",
                attrs: { model: _vm.tagForms, rules: _vm.tagRules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "coinTagName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "名称" },
                    model: {
                      value: _vm.tagForms.coinTagName,
                      callback: function($$v) {
                        _vm.$set(_vm.tagForms, "coinTagName", $$v)
                      },
                      expression: "tagForms.coinTagName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "标签分类", prop: "coinTagCategory" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "标签分类" },
                      model: {
                        value: _vm.tagForms.coinTagCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.tagForms, "coinTagCategory", $$v)
                        },
                        expression: "tagForms.coinTagCategory"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("别名标签")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("钱币性质标签")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }